<script setup lang="ts">
import { ref, onMounted } from 'vue'
import emailjs from '@emailjs/browser'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const form = ref({
  name: '',
  email: '',
  subject: '',
  message: ''
})

const loading = ref(false)
const success = ref(false)
const error = ref('')
const turnstileWidgetId = ref('')
const turnstileToken = ref('')

// onMounted(() => {
//   // Load Cloudflare Turnstile script
//   const script = document.createElement('script')
//   script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
//   script.async = true
//   script.defer = true
//   script.onload = () => {
//     initTurnstile()
//   }
//   script.onerror = () => {
//     console.error('Failed to load Turnstile script')
//     error.value = t('contact.form.captchaError')
//   }
//   document.head.appendChild(script)
// })

// // Initialize Turnstile
// const initTurnstile = () => {
//   if (!window.turnstile) {
//     console.error('Turnstile not loaded')
//     error.value = t('contact.form.captchaError')
//     return
//   }

//   try {
//     turnstileWidgetId.value = window.turnstile.render('#turnstile-widget', {
//       sitekey: '0x4AAAAAABDpE6eOrpzlLlKZ',
//       callback: (token: string) => {
//         turnstileToken.value = token
//       }
//     })
//   } catch (err) {
//     console.error('Turnstile initialization error:', err)
//     error.value = t('contact.form.captchaError')
//   }
// }

const sendEmail = async (e: Event) => {
  e.preventDefault()
  loading.value = true
  error.value = ''
  success.value = false

  // try {
  // // Verify CAPTCHA first
  // if (!window.turnstile || !turnstileToken.value) {
  //   throw new Error(t('contact.form.captchaError'))
  // }

  // // Verify the token with Cloudflare
  // const verifyResponse = await fetch('https://challenges.cloudflare.com/turnstile/v0/siteverify', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     secret: '0x4AAAAAABDpE-VW_CRp3t-y9RgqAQNMlM0', // Replace with your actual private key
  //     response: turnstileToken.value,
  //   }),
  // })

  // const verifyData = await verifyResponse.json()
  // if (!verifyData.success) {
  //   throw new Error(t('contact.form.captcha'))
  // }
  try {
    await emailjs.send(
      'service_a2yu7oh',
      'template_n5dqqjg',
      {
        from_name: form.value.name + ' ' + form.value.email,
        subject: form.value.subject,
        message: form.value.message,
      },
      'D6MY75Uq0FxbwkhdD'
    )
    success.value = true
    form.value = {
      name: '',
      email: '',
      subject: '',
      message: ''
    }

    loading.value = false
    // Reset CAPTCHA
    //   window.turnstile.reset(turnstileWidgetId.value)
    //   turnstileToken.value = ''
  } catch (err) {
    error.value = err instanceof Error ? err.message : t('contact.form.error')
    //   console.error('Error sending email:', err)
  } finally {
    loading.value = false
  }
}

// Add TypeScript declarations for Cloudflare Turnstile
declare global {
  interface Window {
    turnstile?: {
      ready: (callback: () => void) => void
      render: (container: string, options: { sitekey: string; callback?: (token: string) => void }) => string
      execute: (widgetId: string) => Promise<string>
      reset: (widgetId: string) => void
    }
  }
}
</script>

<template>
  <section id="contact" class="contact-section py-5">
    <div class="container">
      <div class="text-center mb-5">
        <h2 class="section-title">{{ t('contact.title') }}</h2>
        <!-- <p class="section-subtitle">Jeigu turite projektų ar ieškote patikimų IT specialistų – susisiekite su mumis ir -->
        <!-- aptarsime bendradarbiavimo galimybes!</p> -->
      </div>
      <div class="row g-5">

        <div class="col-lg-5">
          <h3 class="h4 mb-5">{{ t('contact.connect') }}</h3>
          <p class="lead mb-5">{{ t('contact.intro') }}</p>

          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-user"></i>
            </div>
            <div>
              <h5 class="mb-1">ARŪNAS STARINSKAS</h5>
              <p class="mb-0">{{ t('contact.ceo') }}</p>
              <p class="mb-0">arunas.starinskas@interstartas.lt</p>
            </div>
          </div>

          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-user"></i>
            </div>
            <div>
              <h5 class="mb-1">LUKAS ANUŽIS</h5>
              <p class="mb-0">{{ t('contact.cto') }}</p>
              <p class="mb-0">lukas.anuzis@interstartas.lt</p>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <form class="contact-form" @submit="sendEmail">
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label class="form-label">{{ t('contact.form.name') }}</label> -->
                  <input type="text" class="form-control" :placeholder="t('contact.form.name')" v-model="form.name"
                    required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label class="form-label">{{ t('contact.form.email') }}</label> -->
                  <input type="email" class="form-control" :placeholder="t('contact.form.email')" v-model="form.email"
                    required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <!-- <label class="form-label">{{ t('contact.form.subject') }}</label> -->
                  <input type="text" class="form-control" :placeholder="t('contact.form.subject')"
                    v-model="form.subject" required>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <!-- <label class="form-label">{{ t('contact.form.message') }}</label> -->
                  <textarea class="form-control" rows="5" :placeholder="t('contact.form.message')"
                    v-model="form.message" required></textarea>
                </div>
              </div>
              <!-- <div class="col-12">
                <div id="turnstile-widget"></div>
              </div> -->
              <div class="col-12">
                <button type="submit" class="btn btn-primary w-100" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status"
                    aria-hidden="true"></span>
                  {{ loading ? t('contact.form.sending') : t('contact.form.submit') }}
                </button>
              </div>
              <div v-if="success" class="col-12">
                <div class="alert alert-success">
                  {{ t('contact.form.success') }}
                </div>
              </div>
              <div v-if="error" class="col-12">
                <div class="alert alert-danger">
                  {{ error }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.contact-section {
  background-color: #f8f9fa;
}

.contact-info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.contact-info-icon {
  width: 40px;
  height: 40px;
  background-color: #0d2c6e;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  flex-shrink: 0;
}

.contact-form .form-control {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
}

.contact-form .form-control:focus {
  border-color: #0d2c6e;
  box-shadow: 0 0 0 0.2rem rgba(13, 44, 110, 0.25);
}

.btn-primary {
  background-color: #0d2c6e;
  border-color: #0d2c6e;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
}

.btn-primary:hover {
  background-color: #1440a0;
  border-color: #1440a0;
}

.btn-primary:disabled {
  background-color: #0d2c6e;
  border-color: #0d2c6e;
  opacity: 0.7;
}

.alert {
  margin-top: 1rem;
  border-radius: 0.5rem;
}

#turnstile-widget {
  display: flex;
  justify-content: center;
}

.fa-user {
  color: white !important;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .contact-info-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-info-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .contact-info-item>div:last-child {
    width: 100%;
  }

  .section-title,
  .section-subtitle,
  .h4 {
    text-align: center;
  }

  .lead {
    text-align: center;
  }

  .contact-form {
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
