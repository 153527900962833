<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import Navbar from './components/Navbar.vue'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Services from './views/Services.vue'
import Contact from './views/Contact.vue'
import Footer from './components/Footer.vue'
import CookieConsent from './components/CookieConsent.vue'
import { useAnalytics } from './utils/useAnalytics'

const route = useRoute()
const { trackPageView } = useAnalytics()

onMounted(() => {
  // Track initial page view
  trackPageView(document.title, window.location.pathname)

  // Track page views on route changes
  window.addEventListener('popstate', () => {
    trackPageView(document.title, window.location.pathname)
  })
})

onUnmounted(() => {
  window.removeEventListener('popstate', () => {
    trackPageView(document.title, window.location.pathname)
  })
})
</script>

<template>
  <Navbar />
  <Home />
  <Services />
  <About />
  <Contact />
  <Footer />
  <CookieConsent />
</template>

<style>
html,
body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  overflow-x: hidden;
}

.py-5 {
  padding-top: 6rem !important;
}

.btn-lg {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 1rem;
}

.container {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
</style>