<template>
  <section id="services" class="py-5 bg-light">
    <div class="container">
      <div class="text-center mb-5">
        <h2 class="section-title">{{ $t('services.title') }}</h2>
        <p class="section-subtitle">{{ $t('services.intro') }}</p>
      </div>
      <div class="row g-4">
        <div class="col-md-6 col-lg-4" v-for="(service, index) in services" :key="index">
          <div class="card service-card">
            <div class="card-body text-center p-4">
              <div class="service-icon">
                <i :class="service.icon + ' fa-2x text-primary'"></i>
              </div>
              <h4 class="card-title h5 mb-3">{{ $t(service.titleKey) }}</h4>
              <p class="card-text text-muted">{{ $t(service.descriptionKey) }}</p>
              <!-- <a href="#contact" class="btn btn-outline-primary mt-3">Sužinoti daugiau</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services',
  data() {
    return {
      services: [
        {
          titleKey: 'services.services.dotnet.title',
          descriptionKey: 'services.services.dotnet.description',
          icon: 'fas fa-code'
        },
        {
          titleKey: 'services.services.oracleApex.title',
          descriptionKey: 'services.services.oracleApex.description',
          icon: 'fas fa-database'
        },
        {
          titleKey: 'services.services.oracleDb.title',
          descriptionKey: 'services.services.oracleDb.description',
          icon: 'fas fa-server'
        },
        {
          titleKey: 'services.services.sql.title',
          descriptionKey: 'services.services.sql.description',
          icon: 'fas fa-database'
        },
        {
          titleKey: 'services.services.dataProcessing.title',
          descriptionKey: 'services.services.dataProcessing.description',
          icon: 'fas fa-chart-line'
        },
        {
          titleKey: 'services.services.serverMaintenance.title',
          descriptionKey: 'services.services.serverMaintenance.description',
          icon: 'fas fa-server'
        },
        {
          titleKey: 'services.services.processAutomation.title',
          descriptionKey: 'services.services.processAutomation.description',
          icon: 'fas fa-robot'
        },
        {
          titleKey: 'services.services.processDigitization.title',
          descriptionKey: 'services.services.processDigitization.description',
          icon: 'fas fa-digital-tachograph'
        },
        {
          titleKey: 'services.services.guptaProgramming.title',
          descriptionKey: 'services.services.guptaProgramming.description',
          icon: 'fas fa-desktop'
        }
      ]
    }
  }
}
</script>

<style>
.service-card {
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #0d2c6e;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.service-card:hover::before {
  transform: scaleX(1);
}

.service-icon {
  margin-bottom: 1.5rem;
}
</style>