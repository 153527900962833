<template>
  <section id="about" class="py-5">
    <div class="container text-center">
      <div class=" mb-5">
        <h2 class="section-title">{{ $t('about.title') }}</h2>
        <!-- <p class="section-subtitle">UAB "Interstartas"</p> -->
      </div>
      <div class="row align-items-center gy-4">
        <div class="col-lg-12">
          <div class="about-content">
            <p class="mb-4">{{ $t('about.content1') }}</p>

            <p class="mb-4">{{ $t('about.content2') }}</p>

            <h3 class="h4 mb-4">{{ $t('about.whyChooseUs') }}</h3>

            <div class="row g-4 mb-4">
              <div class="col-md-6" v-for="feature in features" :key="feature.title">
                <div class="about-feature">
                  <i :class="feature.icon"></i>
                  <h4 class="h5 mb-2">{{ $t(feature.titleKey) }}</h4>
                  <p class="mb-0">{{ $t(feature.descriptionKey) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      features: [
        {
          icon: 'fas fa-users',
          titleKey: 'about.features.flexibility.title',
          descriptionKey: 'about.features.flexibility.description'
        },
        {
          icon: 'fas fa-star',
          titleKey: 'about.features.experience.title',
          descriptionKey: 'about.features.experience.description'
        },
        {
          icon: 'fas fa-bolt',
          titleKey: 'about.features.efficiency.title',
          descriptionKey: 'about.features.efficiency.description'
        },
        {
          icon: 'fas fa-shield-alt',
          titleKey: 'about.features.security.title',
          descriptionKey: 'about.features.security.description'
        }
      ]
    }
  }
}
</script>

<style scoped>
.about-content {
  font-size: 1.1rem;
  line-height: 1.8;
}

.about-feature {
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
  height: 100%;
  transition: transform 0.3s ease;
}

.about-feature:hover {
  transform: translateY(-5px);
}

.about-feature i {
  font-size: 2rem;
  color: #0d6efd;
  margin-bottom: 1rem;
}

.service-icon {
  background-color: #0d2c6e !important;
}
</style>