<template>
  <nav class="navbar navbar-expand-lg fixed-top" :class="{ 'navbar-scrolled': isScrolled }">
    <div class="container px-4">
      <a class="navbar-brand" href="#">INTERSTARTAS</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" href="#home">{{ $t('nav.home') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">{{ $t('nav.services') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">{{ $t('nav.about') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">{{ $t('nav.contact') }}</a>
          </li>
        </ul>
        <div class="language-switcher">
          <button class="btn btn-link nav-link" @click="toggleLanguage">
            {{ locale === 'en' ? 'LT' : 'EN' }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAnalytics } from '../utils/useAnalytics'

const { locale } = useI18n()
const { trackLanguageChange } = useAnalytics()
const isScrolled = ref(false)

const handleScroll = () => {
  isScrolled.value = window.scrollY > 50
}

const toggleLanguage = () => {
  const newLocale = locale.value === 'en' ? 'lt' : 'en'
  locale.value = newLocale
  if (localStorage.getItem('cookieConsent') === 'accepted') {
    localStorage.setItem('language', newLocale)
    trackLanguageChange(newLocale)
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  const savedLanguage = localStorage.getItem('language')
  if (savedLanguage && localStorage.getItem('cookieConsent') === 'accepted') {
    locale.value = savedLanguage
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.navbar {
  transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0);
  height: 76px;
  /* Fixed height for the navbar */
  display: flex;
  align-items: center;
}

.navbar-scrolled {
  background-color: rgba(255, 255, 255, 1) !important;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  color: white !important;
  transition: color 0.5s ease-in-out;
}

.navbar-scrolled .navbar-brand,
.navbar-scrolled .nav-link {
  color: #0d2c6e !important;
  transition: color 0.5s ease-in-out;
}

.nav-link {
  color: white !important;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}

.navbar-scrolled .nav-link:hover {
  color: #1440a0 !important;
}

.nav-link:hover {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-scrolled .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.5);
}

.navbar-scrolled .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.language-switcher {
  margin-left: 1rem;
}

.language-switcher .btn-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 0.25rem;
  transition: all 0.3s ease;
}

.language-switcher .btn-link:hover {
  color: #1440a0;
}

.language-switcher .btn-link.active {
  color: #1440a0;
  font-weight: bold;
}

.navbar-scrolled .language-switcher .btn-link {
  color: #0d2c6e;
}

.navbar-scrolled .language-switcher .btn-link:hover,
.navbar-scrolled .language-switcher .btn-link.active {
  color: #1440a0;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 0.5rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .navbar-collapse .nav-link {
    color: #333 !important;
  }

  .language-switcher {
    margin-left: 0;
    margin-top: 0.5rem;
    text-align: center;
  }
}
</style>